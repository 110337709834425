.widget_categories
	ul
		li
			display: flex !important
			justify-content: space-between
			align-items: center
			flex-wrap: wrap
			span
				font-size: 14px
				line-height: 1
