
@media screen and (max-width: $md)
	.section-masthead__background_halfscreen-home.section-masthead__background_halfscreen 
			height: 50vh


.uppercase
	text-transform: uppercase

.text-wider
	letter-spacing: .15rem

.size-18 
	font-size: 18px
	line-height: 28px

@media screen and (max-width: $md)
	.size-18 
		font-size: 11px
		line-height: 18px

.size-16-18 
	font-size: 18px
	line-height: 28px

@media screen and (max-width: $md)
	.size-16-18 
		font-size: 16px
		line-height: 22px

//.section-fullheight__inner-nofull

.section-fullheight__inner_mobile-nofull
	min-height: calc(var(--fix-bar-vh, 1vh) * 70)

.slider__bg.contain
	background-size: contain


.counter__label
	color: var(--xl-color-light)

@media screen and (min-width: 320px)
	.counter__number
		font-size: 66px


.aside-counters_2, .aside-counters_3, .aside-counters_4
		.aside-counters__wrapper-item
			&:after
				display: none

@media screen and (min-width: $md)
	.aside-counters_2, .aside-counters_3, .aside-counters_4
		.aside-counters__wrapper-item
			&:after
				display: block
				background-color: var(--xl-color-light)

@media screen and (max-width: $md)
	.aside-counters:after
		content: ""
		display: block
		position: absolute
		top: 0
		width: 40%
		left: 30%
		height: 1px
		background-color: #95774C

@media screen and (max-width: $md)
	.aside-counters:before
		content: ""
		display: block
		position: absolute
		bottom: 0
		width: 40%
		left: 30%
		height: 1px
		background-color: #95774C

@media screen and (max-width: $md)
	.footer-menu svg
		transform: scale(0.7)
	.footer-menu:after
		content: ""
		display: block
		position: absolute
		top: 0
		width: 80%
		left: 10%
		height: 1px
		background-color: #95774C

.circle-button_link
	.circle-button__label
		color: var(--color-dark-3)


.circle-button__outer-inside .circle-button__label
	font-size: 12px
	letter-spacing: -1px

//@media screen and (min-width: $md)
//	.slider-images-custom .swiper-slide
//		margin-right: 64px

//@media screen and (max-width: $md)
//	.slider-images-custom .swiper-slide, .slider-images-custom-borders .swiper-slide
//		margin-right: 20px !important

[data-arts-theme-text="light"]
	.slider__dot svg .circle
		stroke: var(--color-gray-1)
	.slider__dot
		border-color: var(--color-gray-1)
		&:after
			background-color: var(--color-gray-1)
	.slider__dot_active
		&:after
			background-color: var(--color-gray-1)

@media screen and (min-width: $md)
	.slider-images-custom-borders
		background-color: #322620
		padding: 140px 140px 50px 140px

@media screen and (max-width: $md)
	.slider-images-custom-borders, .slider-images-custom
		margin-left: -45px
		.slider-services__footer
			margin-left: 23px

@media screen and (max-width: $md)
	.line-top-left:after
		content: ""
		display: block
		position: absolute
		top: 0
		width: 30%
		height: 1px
		background-color: #95774C

@media screen and (max-width: $md)
	.line-top:after
		content: ""
		display: block
		position: absolute
		top: 0
		left: 35%
		width: 30%
		height: 1px
		background-color: #95774C

@media screen and (min-width: $md)
	.line-bottom-left:before
		content: ""
		display: block
		position: absolute
		bottom: 0
		width: 30%
		height: 1px
		background-color: #95774C

.h1.small, .h2.small
	@include fluid-type(35, 50)
	line-height: var(--h1-line-height)

.underline
	text-decoration: underline

.color-light
	color: var(--paragraph-color-light) !important

.swiper-slide-ratio-729
	width: calc(38.3vw + 25px) !important

@media screen and (min-width: $md)
	.swiper-slide-ratio-729
		width: 38.3vw !important

.swiper-slide-ratio-1399
	width: calc(73.8vw + 25px) !important

@media screen and (min-width: $md)
	.swiper-slide-ratio-1399
		width: 73.8vw !important
		

.swiper-slide-ratio-612
	width: calc(32.3vw + 25px) !important

@media screen and (min-width: $md)
	.swiper-slide-ratio-612
		width: 32.3vw !important

.swiper-slide-2
	//width: auto
.swiper-slide-2 .section-content__image,.swiper-slide-2 .section-content__image-inner,.swiper-slide-2 .section-image
	height: 100%
	//width: auto
.swiper-slide-2 img
	//height: 100%
	//width: auto


.swiper-wrapper-custom-heights
	width: 80%

.slider-custom .h2
	@include fluid-type(35, 65)
.slider-custom .h3
	@include fluid-type(35, 55)

@media screen and (max-width: $md)
	.slider-custom .swiper-container
		position: relative
	.slider-custom .slider__overlay
		display: none
	.slider-custom .slider-halfscreen-projects__images
		height: 480px
	.slider-custom
		display: flex
		flex-direction: column
		position: relative
		.slider__arrow_left
			position: relative
			top: auto
			width: 42px
			margin-right: auto
			transform: translate(0,100%)
		.slider__arrow_right
			position: relative
			top: auto
			width: 42px
			margin-left: auto
			transform: none
		.slider__wrapper-counter
			right: auto
			margin: auto
			position: relative
			order: 5
			margin-top: -23px
		.js-slider-fullscreen-projects__content
			max-width: 100%
			margin-right: 20px
		.swiper-slide
			width: 100%
		h2
			margin: auto
			display: block



.menu
	@include fluid-type(28, 95)
	line-height: var(--h2-line-height)
	
.circle-button_link-custom .circle-button__icon svg
	opacity: 0
	transition: all 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86)

.circle-button_link-custom:hover .circle-button__icon svg
	opacity: 1


.h2-md-smaller
	@include fluid-type(35, 50)

@media screen and (min-width: $md)
	.md-block
		display: block


@media screen and (min-width: $md)
	.ml-md-0
		margin-left: 0
	.mr-md-0
		margin-right: 0

.d-none 
	display: none!important

@media screen and (min-width: $md)
	.md-show
		display: block!important


@media screen and (min-width: $lg)
	.d-lg-block
		display: block!important

@media screen and (min-width: $md) // 1600
	.justify-content-xxl-between
		justify-content: space-between!important

.lazy-bg
	background-color: rgb(228, 224, 212)


#cookiesModal
	height: auto
	top: auto
	bottom: 0
	overflow: hidden
	z-index: 50
	padding-right: 0 !important
    
#cookiesModal .modal-dialog
	width: 100%
	max-width: 100%
	margin: 0
	border-radius: 0
	transform: translate(0, 150px)
	overflow: hidden

#cookiesModal .modal-content
	border-radius: 0
	background-color: #B0967D
	color: #1E110C
	padding: 0

.modal-backdrop
	display: none !important

#cookiesModal.modal.fade .modal-dialog
	transform: translate(0, 150px)

#cookiesModal.modal.show .modal-dialog
	transform: translate(0, 0)


#cookiesModal .h4
	font-size: 20px

#cookiesModal .cookies-accept,#cookiesModal .cookies-decline
	font-size: 15px
	padding: 8px 43px
	border: none
	outline: none
	width: 100%

#cookiesModal .cookies-accept.h4,#cookiesModal .cookies-decline.h4
	font-size: 15px

#cookiesModal .cookies-accept
	border: 1px solid #1E110C
	background-color: #1E110C
	color: #B0967D
	transition: all 0.3s ease
#cookiesModal .cookies-accept:hover
		border: 1px solid #95774C
		background-color: #95774C
		color: #1E110C


#cookiesModal .cookies-decline
	border: 1px solid #1E110C
	background: transparent
	transition: all 0.3s ease
#cookiesModal .cookies-decline:hover
		border: 1px solid #95774C
		background-color: #95774C

#cookiesModal a
	font-weight: bold
	color: #1E110C
	text-decoration: none !important

.modal-open
	//overflow: auto
	padding-right: 0 !important

@media screen and (min-width: $md)
	.pointer-events-none-desktop
		pointer-events: none

.data-arts-os-animation-cut-transitions
	//transform: none !important
	opacity: 1 !important