// Margin All Sides
.m-xsmall
	@include fluid('margin', $distance-min-xsmall, $distance-max-xsmall)
.m-small
	@include fluid('margin', $distance-min-small, $distance-max-small)
.m-medium
	@include fluid('margin', $distance-min-medium, $distance-max-medium)
.m-large
	@include fluid('margin', $distance-min-large, $distance-max-large)
.m-xlarge
	@include fluid('margin', $distance-min-xlarge, $distance-max-xlarge)

// Margin Vertical
.my-xsmall
	@include fluid('margin-top', $distance-min-xsmall, $distance-max-xsmall)
	@include fluid('margin-bottom', $distance-min-xsmall, $distance-max-xsmall)
.my-small
	@include fluid('margin-top', $distance-min-small, $distance-max-small)
	@include fluid('margin-bottom', $distance-min-small, $distance-max-small)
.my-medium
	@include fluid('margin-top', $distance-min-medium, $distance-max-medium)
	@include fluid('margin-bottom', $distance-min-medium, $distance-max-medium)
.my-large
	@include fluid('margin-top', $distance-min-large, $distance-max-large)
	@include fluid('margin-bottom', $distance-min-large, $distance-max-large)
.my-xlarge
	@include fluid('margin-top', $distance-min-xlarge, $distance-max-xlarge)
	@include fluid('margin-bottom', $distance-min-xlarge, $distance-max-xlarge)

// Margin Horizontal
.mx-xsmall
	@include fluid('margin-left', $distance-min-xsmall, $distance-max-xsmall)
	@include fluid('margin-right', $distance-min-xsmall, $distance-max-xsmall)
.mx-small
	@include fluid('margin-left', $distance-min-small, $distance-max-small)
	@include fluid('margin-right', $distance-min-small, $distance-max-small)
.mx-medium
	@include fluid('margin-left', $distance-min-medium, $distance-max-medium)
	@include fluid('margin-right', $distance-min-medium, $distance-max-medium)
.mx-large
	@include fluid('margin-left', $distance-min-large, $distance-max-large)
	@include fluid('margin-right', $distance-min-large, $distance-max-large)
.mx-xlarge
	@include fluid('margin-left', $distance-min-xlarge, $distance-max-xlarge)
	@include fluid('margin-right', $distance-min-xlarge, $distance-max-xlarge)
	
// Margin Top
.mt-xsmall
	@include fluid('margin-top', $distance-min-xsmall, $distance-max-xsmall)
.mt-small
	@include fluid('margin-top', $distance-min-small, $distance-max-small)
.mt-medium
	@include fluid('margin-top', $distance-min-medium, $distance-max-medium)
.mt-large
	@include fluid('margin-top', $distance-min-large, $distance-max-large)
.mt-xlarge
	@include fluid('margin-top', $distance-min-xlarge, $distance-max-xlarge)

// Margin Right
.mr-xsmall
	@include fluid('margin-right', $distance-min-xsmall, $distance-max-xsmall)
.mr-small
	@include fluid('margin-right', $distance-min-small, $distance-max-small)
.mr-medium
	@include fluid('margin-right', $distance-min-medium, $distance-max-medium)
.mr-large
	@include fluid('margin-right', $distance-min-large, $distance-max-large)
.mr-xlarge
	@include fluid('margin-right', $distance-min-xlarge, $distance-max-xlarge)

// Margin Bottom
.mb-xsmall
	@include fluid('margin-bottom', $distance-min-xsmall, $distance-max-xsmall)
.mb-small
	@include fluid('margin-bottom', $distance-min-small, $distance-max-small)
.mb-medium
	@include fluid('margin-bottom', $distance-min-medium, $distance-max-medium)
.mb-large
	@include fluid('margin-bottom', $distance-min-large, $distance-max-large)
.mb-xlarge
	@include fluid('margin-bottom', $distance-min-xlarge, $distance-max-xlarge)

// Margin Left
.ml-xsmall
	@include fluid('margin-left', $distance-min-xsmall, $distance-max-xsmall)
.ml-small
	@include fluid('margin-left', $distance-min-small, $distance-max-small)
.ml-medium
	@include fluid('margin-left', $distance-min-medium, $distance-max-medium)
.ml-large
	@include fluid('margin-left', $distance-min-large, $distance-max-large)
.ml-xlarge
	@include fluid('margin-left', $distance-min-xlarge, $distance-max-xlarge)
