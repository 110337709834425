.logo
	position: relative
	display: inline-flex
	align-items: center
	vertical-align: middle
.logo__wrapper-img
	position: relative
	align-items: center
	flex: 1 0 auto
	height: 100%
	margin-right: 15px
	img
		+trans1
		display: block
.logo__text-title
	display: block
	font-family: $font-secondary
	font-size: 20px
	font-weight: bold
	line-height: 1.3
	color: var(--paragraph-color-dark)
.logo__text-tagline
	display: block
	font-style: italic
	font-size: 12px
	font-weight: bold
	line-height: 1.3
.logo__img-secondary
	position: absolute
	top: 0
	left: 0
	opacity: 0
	visibility: hidden
.logo__description
	margin-top: 1em

@media screen and (max-width: $md)
	.logo__wrapper-img
		img
			width: 97px