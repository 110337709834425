// Margin Top Negative
.mt-minus-xsmall
	@include fluid('margin-top', $distance-min-xsmall, $distance-max-xsmall, $min_width, $max_width, -1)
.mt-minus-small
	@include fluid('margin-top', $distance-min-small, $distance-max-small, $min_width, $max_width, -1)
.mt-minus-medium
	@include fluid('margin-top', $distance-min-medium, $distance-max-medium, $min_width, $max_width, -1)
.mt-minus-large
	@include fluid('margin-top', $distance-min-large, $distance-max-large, $min_width, $max_width, -1)
.mt-minus-xlarge
	@include fluid('margin-top', $distance-min-xlarge, $distance-max-xlarge, $min_width, $max_width, -1)

// Margin Right Negative
.mr-minus-xsmall
	@include fluid('margin-right', $distance-min-xsmall, $distance-max-xsmall, $min_width, $max_width, -1)
.mr-minus-small
	@include fluid('margin-right', $distance-min-small, $distance-max-small, $min_width, $max_width, -1)
.mr-minus-medium
	@include fluid('margin-right', $distance-min-medium, $distance-max-medium, $min_width, $max_width, -1)
.mr-minus-large
	@include fluid('margin-right', $distance-min-large, $distance-max-large, $min_width, $max_width, -1)
.mr-minus-xlarge
	@include fluid('margin-right', $distance-min-xlarge, $distance-max-xlarge, $min_width, $max_width, -1)

// Margin Bottom Negative
.mb-minus-xsmall
	@include fluid('margin-bottom', $distance-min-xsmall, $distance-max-xsmall, $min_width, $max_width, -1)
.mb-minus-small
	@include fluid('margin-bottom', $distance-min-small, $distance-max-small, $min_width, $max_width, -1)
.mb-minus-medium
	@include fluid('margin-bottom', $distance-min-medium, $distance-max-medium, $min_width, $max_width, -1)
.mb-minus-large
	@include fluid('margin-bottom', $distance-min-large, $distance-max-large, $min_width, $max_width, -1)
.mb-minus-xlarge
	@include fluid('margin-bottom', $distance-min-xlarge, $distance-max-xlarge, $min_width, $max_width, -1)

// Margin Left Negative
.ml-minus-xsmall
	@include fluid('margin-left', $distance-min-xsmall, $distance-max-xsmall, $min_width, $max_width, -1)
.ml-minus-small
	@include fluid('margin-left', $distance-min-small, $distance-max-small, $min_width, $max_width, -1)
.ml-minus-medium
	@include fluid('margin-left', $distance-min-medium, $distance-max-medium, $min_width, $max_width, -1)
.ml-minus-large
	@include fluid('margin-left', $distance-min-large, $distance-max-large, $min_width, $max_width, -1)
.ml-minus-xlarge
	@include fluid('margin-left', $distance-min-xlarge, $distance-max-xlarge, $min_width, $max_width, -1)
