.lazy
	background-color: rgba(136, 136, 136, .1)
	> img
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
.lazy_loaded
	background-color: unset
.lazy__img:not(img)
	background-size: cover
	background-position: center center
	background-repeat: no-repeat
.lazy-bg
	width: 100%
	height: 100%
	background-size: cover
	background-position: center center
	background-repeat: no-repeat
	background-color: rgba(136, 136, 136, .1)
.lazy-bg_loaded
	background-color: unset
