$em-base: 18px
$font-primary: var(--font-primary)
$font-secondary: var(--font-secondary)
$line-height: 2.0

// Color palette
$black: var(--color-dark-1)
$black-2: var(--color-dark-2)
$dark: var(--color-dark-3)
$dark-2: var(--color-dark-4)
$pale: var(--color-light-1)
$off-white: var(--color-light-2)
$off-white-2: var(--color-light-3)
$warm-white: var(--color-light-4)
$brown-grey: var(--color-gray-1)
$very-light-pink: var(--color-gray-2)
$borders-light: rgba(128, 128, 128, .4)
$borders-light-2: rgba(128, 128, 128, .7)
$borders-dark: rgba(128, 128, 128, .3)
$borders-dark-2: rgba(128, 128, 128, .5)

// Responsive Breakpoints
$xxl: 1400px
$xl: 1280px
$lg: 1024px
$md: 991px
$sm: 767px
$xs: 576px

// Default min/max screen width
$min_width: 320px
$max_width: 2560px

$distance-max-xsmall: var(--distance-max-xsmall)
$distance-max-small: var(--distance-max-small)
$distance-max-medium: var(--distance-max-normal)
$distance-max-large: var(--distance-max-large)
$distance-max-xlarge: var(--distance-max-xlarge)
$distance-min-xsmall: var(--distance-min-xsmall)
$distance-min-small: var(--distance-min-small)
$distance-min-medium: var(--distance-min-medium)
$distance-min-large: var(--distance-min-large)
$distance-min-xlarge: var(--distance-min-xlarge)

$subheading-max-font-size: var(--subheading-max-font-size)
$subheading-min-font-size: var(--subheading-min-font-size)
$subheading-line-height: var(--subheading-line-height)
