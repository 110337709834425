.counter__number
	font-family: $font-secondary
	@include fluid-type(43, 104)
	font-weight: 200
	line-height: 1
.counter__label
	margin-top: 5px
	color: $brown-grey
	line-height: 1.5
	@include fluid-type(14, 18)
